/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

module.exports = {
  // onClientEntry: () => {
  //   if (
  //     window.location.pathname !== "/under-construction/"
  //   ) {
  //     window.location = "/under-construction"
  //   }
  // },
  // onRouteUpdate: () => {
  //   if (
  //     window.location.pathname !== "/under-construction/"
  //   ) {
  //     window.location = "/under-construction"
  //   }
  // },
}
